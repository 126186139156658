<template>
  <div class="accent">
    <v-snackbar v-model="snackback" color="success" top>
      <span> Invitation was sent successful</span>
      <v-btn depressed color="white" @click="snackback = false">close</v-btn>
    </v-snackbar>
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'ServiceProvider' }"
              >Service Provider</router-link
            >
            | <span class="title"> Car Rentals</span>
          </h1>
        </v-col>
      </v-row>
      <v-container>
        <v-row class="mt-10">
          <v-col class="ma-auto" cols="12" md="12">
            <app-car-rental-table />
          </v-col>
        </v-row>
      </v-container>
      <app-invite-vendor />
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    appCarRentalTable: () =>
      import('@/components/vendor-managment/car-rental/CarRentalTable'),
    appInviteVendor: () => import('@/components/vendor-managment/VendorInvite'),
  },
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    snackback: false,
  }),
}
</script>
